
.slideBar .ant-collapse-content-box {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 12px !important;
}

/* .slideBar .ant-collapse-header {
    padding: 4px 12px !important;
} */

.paddingXXS .ant-collapse-header {
    padding: 4px;
}

.slideBar .menu-link a {
    color: #222;
}

.slideBar .menu-link a:hover,
.slideBar .menu-link a.active {
    color: #34beef;
}