.cursor-pointer {
    cursor: pointer;
}
.lamnha-link {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #444;
    transition: all ease 0.3s;
    text-decoration: none;
}
.lamnha-link:hover {
    color: #34beef;
}
.ic-arrow-lamnha img {
    transition: all 0.2s ease 0s;
    transform: rotate(180deg);
}
.lamnha-absolute-img img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    height: auto;
}
.btn-lamnha {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-radius: 8px;
    background: #34beef;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #ffffff;
    position: relative;
}
.mw-943 {
    max-width: 943px;
    margin: 0 auto;
}
/* header */
.header-lamnha {
    padding: 16px 0;
    position: relative;
    z-index: 100;
}
.header-lamnha-main {
    display: flex;
    align-items: center;
}
.header-lamnha-main .menu-lamnha-right {
    margin-left: auto;
    position: relative;
    z-index: 10;
}
.header-lamnha-main .btn-book {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-radius: 8px;
    background: #34beef;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #ffffff;
    position: relative;
}

.header-lamnha-main .btn-book .ic-arrow-lamnha {
    margin-left: 4px;
}
.header-lamnha-main .btn-book .lamnha-dropdown-head,
.header-lamnha-main .menu-lamnha-link .lamnha-dropdown-head {
    padding-top: 17px;
}
.header-lamnha-main .menu-lamnha-link .lamnha-dropdown-head {
    right: 0;
    left: auto;
}
.header-lamnha-main .btn-book:hover .ic-arrow-lamnha img {
    transform: rotate(0);
}
.header-lamnha-main .btn-book:hover .lamnha-dropdown-head,
.header-lamnha-main .menu-lamnha-link:hover .lamnha-dropdown-head {
    padding-top: 7px;
    opacity: 1;
    visibility: visible;
}
.header-lamnha-main .menu-lamnha-right-main {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin-left: auto;
    margin-right: 40px;
}
.menu-mb {
    display: none;
}
.lamnha-dropdown-head {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
    min-width: 141px;
}
.lamnha-dropdown-head-main {
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    box-shadow: 15px 15px 32px 0px rgba(26, 26, 26, 0.1);
}
.lamnha-dropdown-head .menu-lamnha-link {
    padding: 16px;
}
.lamnha-dropdown-head .menu-lamnha-link:not(:last-child) {
    border-bottom: 1px solid #f3f3f3;
}
/* end header */
.lamnha-main {
    max-width: 944px;
    margin: 0 auto;
}
.py-40 {
    padding: 40px 0;
}
.py-80 {
    padding: 80px 0;
}
.banner-lamnha-main {
    display: flex;
    flex-wrap: wrap;
}
.banner-lamnha-mb {
    display: none;
}
.banner-lamnha-main .banner-left {
    width: calc(100% - 289px);
}
.banner-lamnha-main .banner-right {
    width: 289px;
    padding-left: 20px;
}
.banner-lamnha-main .banner-right .banner-lamnha-img {
    padding-bottom: 100%;
}
.banner-lamnha-main .banner-right .banner-lamnha-img img {
    position: absolute;
}
.banner-lamnha .banner-lamnha-img {
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}
.banner-lamnha .banner-left .banner-lamnha-img,
.banner-lamnha .banner-lamnha-mb .banner-lamnha-img {
    padding-bottom: 269px;
}
.banner-lamnha .banner-lamnha-img img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.banner-lamnha .slick-dots {
    bottom: 0;
    width: 100%;
    text-align: left;
    padding: 0 32px;
}
/* feadutred eevent */

.featured-event-lamnha {
    background: #34beef url("../../images/lam-nha/bg-featured-event.png")
        no-repeat center center;
}
.featured-event-lamnha .featured-event-lamnha-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.featured-event-lamnha .featured-event-lamnha-main .left {
    width: 420px;
}
.featured-event-lamnha .featured-event-lamnha-main .right {
    flex: 1;
    margin-left: -32px;
    position: relative;
}
.featured-event-lamnha .featured-event-lamnha-main .featured-event-img {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: 12px;
}
.featured-event-lamnha .featured-event-lamnha-main .featured-event-content {
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: #fff;
}
.featured-event-lamnha
    .featured-event-lamnha-main
    .featured-event-content
    .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}
.featured-event-lamnha
    .featured-event-lamnha-main
    .featured-event-content
    .info {
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.featured-event-lamnha
    .featured-event-lamnha-main
    .featured-event-content
    .dots {
    width: 8px;
    height: 8px;
    background: #eee;
    border-radius: 50%;
}
.featured-event-lamnha
    .featured-event-lamnha-main
    .featured-event-content
    .event-status {
    padding: 4px 8px;
    border-radius: 42px;
    background: #ff7572;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #ffffff;
}
/* end feadutredevent */

/* block chains */
.block-chains .box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    margin-bottom: 42px;
}
.block-chains .box-title .chains-number {
    display: inline-block;
    padding: 12px 24px 8px 24px;
    border-radius: 100px;
    background: var(--primary-500, #34beef);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #ffffff;
}
.block-chains .box-title .title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
}
.block-chains .box-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.block-chains .box-content .item {
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    position: relative;
    margin-bottom: 35px;
}
.block-chains .box-content .item .block-chains-img {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: 8px;
}
.block-chains .box-content .item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background: linear-gradient(0deg, #34beef 0%, rgba(52, 190, 239, 0) 100%);
}
.chains-status {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #ffffff;

    border-radius: 51px;
}
.chains-status.ended {
    background: #aaa;
}
.chains-status.running {
    background: #ff7572;
}
.chains-info {
    position: absolute;
    bottom: -25px;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-chains .box-content .item.item-incomming {
    margin-bottom: 0;
}

.block-chains .box-content .item.item-incomming .chains-info {
    bottom: 40px;
}
.chains-info .name {
    text-align: center;
    font-family: "SVN-Gilroy";
    display: inline-block;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: 125%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 40%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding: 8px 0;
    letter-spacing: -2px;
    max-width: 340px;
}
.chains-info .name.name-small {
    font-size: 32px;
}
.chains-info .name.name-small::after {
    -webkit-text-stroke-width: 1.5px;
}
.chains-info .name::after {
    content: attr(title);
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: white;
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    text-align: center;
}
.chains-info .name::before {
    content: "";
    border-bottom: 1px solid #fff;
    opacity: 0.3499999940395355;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.chains-info .type {
    padding: 2px 12px 0px 12px;
    border-radius: 51px;
    background: #278ff7;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: inline-block;
    color: #fff;
}
.chains-info .time {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    padding: 8px 0;
}
.chains-info .time .date,
.chains-info .time .hour {
    padding: 0 16px;
}
.chains-info .time .date {
    border-right: 1px solid #fff;
    border-opacity: 0.35;
}
.chains-info .address {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    max-width: 220px;
    margin: 0 auto 16px;
}
.chains-info .view-more {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 2px solid #278ff7;
    background: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #278ff7;
}
/* end block chains */

/* lo trinh */
.roadmap-lamnha {
    padding: 124px 0;
    background: #f8f8f8;
}
.roadmap-lamnha .title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 42px;
}
.roadmap-lamnha .box-content {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.roadmap-lamnha .box-content::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background: url("../../images/lam-nha/line-roadmap.svg") no-repeat;
    top: 16px;
    z-index: 1;
}
.roadmap-lamnha .box-content .item {
    flex: 0 0 calc(100% / 3) px;
    max-width: calc(100% / 3) px;
}
.roadmap-lamnha .box-content .item .item-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;
}
.roadmap-lamnha .box-content .item .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 41px;
    background: #34beef;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    position: relative;
    z-index: 5;
}
.roadmap-lamnha .box-content .item .time {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 4px;
}
.roadmap-lamnha .box-content .item .info span {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
}
/* end lo trinh */

/* reasonwhy */
.reasonwhy-lamnha {
    padding: 124px 0 64px;
}
.reasonwhy-lamnha .title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 80px;
}
.reasonwhy-lamnha .title span {
    color: #34beef;
}
.reasonwhy-lamnha .box-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.reasonwhy-lamnha .box-content .item {
    flex: 0 0 calc((100% / 3) - (20px * 2 / 3)) px;
    max-width: calc((100% / 3) - (20px * 2 / 3)) px;
    margin-bottom: 60px;
    border-radius: 8px;
    border: 1px solid var(--neutral-400, #eee);
}
.reasonwhy-lamnha .box-content .item-wrap {
    padding: 32px 16px 20px 16px;
    position: relative;
}
.reasonwhy-lamnha .box-content .item-wrap .info {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}
.reasonwhy-lamnha .box-content .item-wrap .number {
    position: absolute;
    top: -30px;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #34beef 0%, #34beef 25%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/* endreasonwhy */

/* review */
.review-lamnha {
    padding: 124px 0 40px;
}
.review-lamnha .title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 80px;
}
.review-lamnha .box-content .review-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.review-lamnha .box-content .review-list .item {
    width: 100%;
    padding: 24px;
    border-radius: 8px;
    border: 2px solid var(--neutral-400, #eee);
    background: var(--neutral-100, #fff);
}
.review-lamnha .box-content .review-list .item .item-wrap {
    display: flex;
    flex-direction: row;
    gap: 0 16px;
}
.review-lamnha .box-content .review-list .item .avatar {
    flex: 0 0 80px;
    max-width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
}
.review-lamnha .box-content .review-list .item .avatar img {
    object-fit: cover;
    object-position: center;
}
.review-lamnha .box-content .review-list .item .name {
    display: block;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
}
.review-lamnha .box-content .review-list .item .rating {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #278ff7;
    margin-bottom: 16px;
}
.review-lamnha .box-content .review-list .item .rating a {
    padding-left: 8px;
}
.review-lamnha .box-content .review-list .item .content {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #222222;
}
.review-lamnha .slick-dots li button {
    width: 8px;
    height: 8px;
    background: #eee;
}
.review-lamnha .slick-dots li.slick-active button {
    background: #34beef;
    width: 8px;
    height: 8px;
}
.review-lamnha .slick-dots {
    position: relative;
    padding-top: 80px;
}
.review-lamnha .slick-dots li,
.review-lamnha .slick-dots li.slick-active,
.review-lamnha .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;

    display: inline-flex;
    align-items: center;
}
/* end review */

/* gallery */
.gallery-lamnha .title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 64px;
}
.gallery-lamnha .box-content .gallery-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.gallery-lamnha .box-content .gallery-list .item {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
}
.gallery-lamnha .slick-dots li button {
    width: 8px;
    height: 8px;
    background: #eee;
}
.gallery-lamnha .slick-dots li.slick-active button {
    background: #34beef;
    width: 8px;
    height: 8px;
}
.gallery-lamnha .slick-dots {
    position: relative;
    padding-top: 42px;
}
.gallery-lamnha .slick-dots li,
.gallery-lamnha .slick-dots li.slick-active,
.gallery-lamnha .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;

    display: inline-flex;
    align-items: center;
}
/* end gallery/ */

/* sponsor */
.sponsor-lamnha .title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 64px;
}
.sponsor-lamnha .box-content {
    position: relative;
}
.sponsor-lamnha .box-content .sponsor-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.sponsor-lamnha .slick-dots li button {
    width: 8px;
    height: 8px;
    background: #eee;
}
.sponsor-lamnha .slick-dots li.slick-active button {
    background: #34beef;
    width: 8px;
    height: 8px;
}
.sponsor-lamnha .slick-dots {
    position: relative;
    padding-top: 42px;
}
.sponsor-lamnha .slick-dots li,
.sponsor-lamnha .slick-dots li.slick-active,
.sponsor-lamnha .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;

    display: inline-flex;
    align-items: center;
}
.sponsor-lamnha .slick-arrow {
    border-radius: 50px;
    border: 1px solid #e7e7e7;
    background: #fff;
    width: 56px;
    height: 56px;
    padding: 16px;
}
.sponsor-lamnha .slick-prev {
    left: -72px;
    z-index: 5;
}
.sponsor-lamnha .slick-next {
    right: -72px;
    z-index: 5;
}
.sponsor-lamnha .show-mb {
    display: none;
}
/* end sponsor */

/* faq */
.faq-lamnha .title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 42px;
}
.faq-lamnha .faq-item {
    border-radius: 12px;
    border: 1.5px solid var(--neutral-500, #ddd);
    background: var(--neutral-100, #fff);
    padding: 24px 32px;
}
.faq-lamnha .faq-item .faq-item-heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: flex;
    align-items: center;
    gap: 16px;
}
.faq-lamnha .faq-item .icon {
    display: inline-block;
    margin-left: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    max-width: 40px;
    height: 40px;
    position: relative;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background-color: #ddd;
    border-radius: 50%;
}
.faq-lamnha .faq-item .icon .horizontal {
    position: absolute;
    background-color: #4a4a4a;
    width: 9px;
    height: 2px;
    left: 50%;
    margin-left: -4.5px;
    top: 50%;
    margin-top: -1.5px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    opacity: 0;
}
.faq-lamnha .faq-item .icon .vertical {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    background-color: #222222;
    position: absolute;
    width: 9px;
    height: 2px;
    left: 50%;
    margin-left: -4.5px;
    top: 50%;
    margin-top: -1.5px;
}
.faq-lamnha .faq-item .faq-item-heading.collapsed .icon {
    background-color: #34beef;
}
.faq-lamnha .faq-item .faq-item-heading.collapsed .icon .horizontal {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
    background-color: #fff;
}
.faq-lamnha .faq-item .faq-item-heading.collapsed .icon .vertical {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    background-color: #fff;
}
.faq-lamnha .faq-item .faq-text {
    padding: 16px 0;
}
/* end faq */

/* register lamnha */
.register-lamnha {
    background: #34beef;
}
.register-lamnha .register-lamnha-main {
    display: flex;
    align-items: center;
    gap: 64px;
}
.register-lamnha .register-lamnha-main .left {
    flex: 0 0 418px;
    max-width: 418px;
}
.register-lamnha .register-lamnha-main .right {
    flex: 1;
}
.register-lamnha .register-lamnha-main .left .img {
    padding-top: 124px;
}
.register-lamnha .register-lamnha-main .box-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
}
.register-lamnha .register-lamnha-main .box-title .title {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 16px;
}
.register-lamnha .register-lamnha-main .register-lamnha-form {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
}
.register-lamnha .register-lamnha-main .register-lamnha-form .custom-control {
    padding-left: 34px;
}
.register-lamnha .register-lamnha-main .register-lamnha-form .item-title {
    margin-bottom: 12px;
}
.register-lamnha .register-lamnha-main .register-lamnha-form .item-cols {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
}
.register-lamnha .register-lamnha-main .register-lamnha-form .item-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px 8px;
}
.register-lamnha .register-lamnha-main .register-lamnha-form .item-cols > div {
    flex: 0 0 calc(50% - 4px);
    max-width: calc(50% - 4px);
}
/* end registerlamnha */

/* detail da dien ra */

.detail-event-lamnha {
    padding: 80px 0;
    background: linear-gradient(180deg, #34beef 0%, #fff 100%);
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
}
.detail-event-lamnha::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event-small.png") no-repeat
        top center;
    width: 592px;
    height: 142px;
}
.detail-event-lamnha .box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 42px;
}
.detail-event-lamnha .box-title .type {
    display: flex;
    padding: 2px 12px 0px 12px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 51px;
    background: #278ff7;
    color: #ffffff;
}
.detail-event-lamnha .box-title .event-status {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}
.detail-event-lamnha .box-title .title {
    display: inline-block;
    font-family: "SVN-Gilroy";

    font-size: 56px;
    font-weight: 900;
    line-height: 150%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 35%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: -2px;
}
.detail-event-lamnha .box-title .title::after {
    content: attr(title);
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.detail-event-lamnha .nd-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.detail-event-lamnha .nd-content img {
    border-radius: 16px;
}
/* end detail da dien ra */

/* handbook da dien ra */

.handbook-lamnha {
    padding: 80px 0;
    background: linear-gradient(180deg, #34beef 0%, #fff 50%);
    position: relative;
}
.handbook-lamnha::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event-small.png") no-repeat
        top center;
    width: 592px;
    height: 142px;
}
.handbook-lamnha .wrap {
}
.handbook-lamnha .box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 42px;
}
.handbook-lamnha .box-title .title {
    display: inline-block;
    font-family: "SVN-Gilroy";
    font-size: 56px;
    font-weight: 900;
    line-height: 150%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 35%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: -2px;
}
.handbook-lamnha .box-title .title::after {
    content: attr(title);
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.handbook-list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 20px;
}
.handbook-list .handbook-item {
    flex: 0 0 calc((100% / 3) - (20px * 2 / 3));
    max-width: calc((100% / 3) - (20px * 2 / 3));
}
.handbook-list .handbook-item .thumbs {
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 50.15%;
    cursor: pointer;
}
.handbook-list .handbook-item .thumbs > a > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}
.handbook-list .handbook-item .thumbs > button {
    position: absolute;
    right: 14px;
    bottom: 14px;
    background-color: transparent;
    border: none;
    z-index: 5;
}
.handbook-list .handbook-item .title {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}
.handbook-list .handbook-item .title a {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #222;
}
.handbook-list .handbook-item .title a:hover {
    color: #34beef;
}
.handbook-list .handbook-item .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 8px;
    font-size: 13px;
    margin-top: 4px;
    color: #666;
    font-weight: 400;
}
/* end handbook da dien ra */

/* goodwill da dien ra */

.goodwill-lamnha {
    padding: 80px 0;
    background: linear-gradient(180deg, #34beef 0%, #fff 100%);
    position: relative;
}
.goodwill-lamnha::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event-small.png") no-repeat
        top center;
    width: 592px;
    height: 142px;
}
.goodwill-lamnha .box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 42px;
}
.goodwill-lamnha .box-title .title {
    display: inline-block;
    font-family: "SVN-Gilroy";
    font-size: 56px;
    font-weight: 900;
    line-height: 150%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 35%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: -2px;
}
.goodwill-lamnha .box-title .title::after {
    content: attr(title);
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.goodwill-lamnha .special-goodwill {
}
.goodwill-lamnha .special-goodwill .thumbs {
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    padding-bottom: 19.8%;
    margin-bottom: 42px;
}
.goodwill-lamnha .special-goodwill .thumbs > a > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.goodwill-lamnha .goodwill-list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 20px;
}
.goodwill-lamnha .goodwill-list .goodwill-item {
    flex: 0 0 calc((100% / 3) - (20px * 2 / 3));
    max-width: calc((100% / 3) - (20px * 2 / 3));
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.goodwill-lamnha .goodwill-list .goodwill-item .title {
    padding: 16px;
}
.goodwill-lamnha .goodwill-list .goodwill-item .title a {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.goodwill-lamnha .goodwill-list .goodwill-item .thumbs {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50.136%;
}
.goodwill-lamnha .goodwill-list .goodwill-item .thumbs img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
/* end handbook da dien ra */

.detail-event-running-lamnha {
    /* padding: 80px 0; */
    /* background: linear-gradient(180deg, #34beef 0%, #fff 100%); */

    font-weight: 500;
    line-height: 28px;
    position: relative;
}
.detail-event-running-lamnha::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event-small.png") no-repeat
        top center;
    width: 592px;
    height: 142px;
    background-size: cover;
}
.detail-event-running-lamnha .box-title-wrap {
    padding: 80px 0;
    background: linear-gradient(180deg, #34beef 0%, #fff 36.64%);
}
.detail-event-running-lamnha .box-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-title .type {
    display: flex;
    padding: 2px 12px 0px 12px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 51px;
    background: #278ff7;
    color: #ffffff;
}
.detail-event-running-lamnha .box-title .title {
    display: inline-block;
    font-family: "SVN-Gilroy";

    font-size: 56px;
    font-weight: 900;
    line-height: 150%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 35%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: -2px;
}
.detail-event-running-lamnha .box-title .title::after {
    content: attr(title);
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.detail-event-running-lamnha .box-info {
    display: flex;
    padding: 22px 0 0 0;
}
.detail-event-running-lamnha .box-info .left {
    flex: 0 0 552px;
    max-width: 552px;
    height: 552px;
}
.detail-event-running-lamnha .box-info .left a {
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
}
.detail-event-running-lamnha .box-info .left img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    object-position: center;
}
.detail-event-running-lamnha .box-info .right {
    flex: 1;
    margin-left: 32px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #333;
}
.detail-event-running-lamnha .box-info .right-info {
    display: flex;
    flex-direction: column;
}
.detail-event-running-lamnha .box-info .item {
    margin-bottom: 32px;
}
.detail-event-running-lamnha .box-info .item-info {
    display: flex;
}
.detail-event-running-lamnha .box-info .item-info .icon {
    flex: 0 0 24px;
    max-width: 24px;
    height: 24px;
    margin-right: 8px;
}
.detail-event-running-lamnha .box-info .item-info .icon img {
    position: relative;
    top: -2px;
}
.detail-event-running-lamnha .box-info .item-info .title {
    white-space: nowrap;
    margin-right: 8px;
    font-weight: 700;
}
.detail-event-running-lamnha .box-main-actions {
    background: #fff;
    padding: 80px 0;
}
.detail-event-running-lamnha .box-main-actions .box-title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-main-actions .box-tab {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-main-actions .box-tab .tab-text {
    text-align: center;
    border-radius: 8px;
    border: 1px solid #eee;
    outline: 1px solid transparent;
    padding: 20px 16px;
    font-size: 16px;
    cursor: pointer;
}
.detail-event-running-lamnha .box-main-actions .box-tab .tab-text.active {
    border: 1px solid #34beef;
    outline: 1px solid #34beef;
    color: #34beef;
}

.detail-event-running-lamnha .box-main-actions .tab-content-wrap {
    display: flex;
}
.detail-event-running-lamnha .box-main-actions .tab-content-wrap .left {
    padding: 32px 0;
    flex: 1;
    margin-right: 64px;
}
.detail-event-running-lamnha .box-main-actions .tab-content-wrap .left .item {
    margin-bottom: 32px;
}
.detail-event-running-lamnha .box-main-actions .tab-content-wrap .right {
    flex: 0 0 443px;
    max-width: 443px;
}

.detail-event-running-lamnha .box-schedule-lamnha {
    padding: 124px 0;
    background: #f8f8f8;
}

.detail-event-running-lamnha .box-schedule-lamnha .list-schedule-lamnha {
    padding: 0 46px;
}
.detail-event-running-lamnha .box-schedule-lamnha .box-title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-schedule-lamnha .item {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    font-size: 18px;
    line-height: 26px;
}
.detail-event-running-lamnha .box-schedule-lamnha .item:nth-child(even) {
    flex-direction: row-reverse;
}
.detail-event-running-lamnha .box-schedule-lamnha .item .info {
    flex: 0 0 454px;
    max-width: 454px;
}
/* .detail-event-running-lamnha .box-schedule-lamnha .item .blank {
    flex: 1;
} */
.detail-event-running-lamnha .box-schedule-lamnha .item .info-wrap {
    display: flex;
    padding: 16px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #eee;
    background: #fff;
}
.detail-event-running-lamnha .box-schedule-lamnha .item .info-wrap .times {
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    padding: 15px 10px;
    color: #ffffff;
    margin-right: 16px;
}
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:first-child
    .info-wrap
    .times,
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(6)
    .info-wrap
    .times {
    background: #65d2f5;
}
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(2)
    .info-wrap
    .times,
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(7)
    .info-wrap
    .times {
    background: #5bd56a;
}
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(3)
    .info-wrap
    .times,
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(8)
    .info-wrap
    .times {
    background: #f77e27;
}
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(4)
    .info-wrap
    .times,
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(9)
    .info-wrap
    .times {
    background: #ff7572;
}
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(5)
    .info-wrap
    .times,
.detail-event-running-lamnha
    .box-schedule-lamnha
    .item:nth-child(10)
    .info-wrap
    .times {
    background: #8155ff;
}
.detail-event-running-lamnha .box-schedule-lamnha .item .count {
    flex: 0 0 32px;
    height: 32px;
    max-width: 32px;
    margin: 0 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #eee;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    position: relative;
}
.detail-event-running-lamnha .box-schedule-lamnha .item:before {
    content: "";
    background: #eee;
    width: 3px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.detail-event-running-lamnha .box-schedule-lamnha .item:first-child:before {
    height: 50%;
    bottom: 0;
}
.detail-event-running-lamnha .box-schedule-lamnha .item:last-child:before {
    height: 50%;
    top: 0;
}

.detail-event-running-lamnha .box-register {
    padding: 80px 0;
    background: #34beef url("../../images/lam-nha/bg-register.png") no-repeat
        center center;
}
.detail-event-running-lamnha .box-register .box-title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 42px;
    color: #ffffff;
}
.detail-event-running-lamnha .box-register .register-lamnha-form {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
}
.detail-event-running-lamnha
    .box-register
    .register-lamnha-form
    .custom-control {
    padding-left: 34px;
}
.detail-event-running-lamnha .box-register .register-lamnha-form .item-title {
    margin-bottom: 12px;
}
.detail-event-running-lamnha .box-register .register-lamnha-form .item-cols {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
}
.detail-event-running-lamnha .box-register .register-lamnha-form .item-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px 8px;
}
.detail-event-running-lamnha .box-register .register-lamnha-form .form-control {
    padding: 12px 16px;
    font-size: 16px;
    height: 48px;
}

.detail-event-running-lamnha
    .box-register
    .register-lamnha-form
    .css-yk16xz-control {
    padding: 3px 16px;
}
.detail-event-running-lamnha
    .box-register
    .register-lamnha-form
    .item-cols
    > div {
    flex: 0 0 calc(50% - 4px);
    max-width: calc(50% - 4px);
}

.detail-event-running-lamnha .box-upcoming {
    padding: 80px 0 0;
}
.detail-event-running-lamnha .box-upcoming .box-title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-upcoming .box-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
}
.detail-event-running-lamnha .box-upcoming .box-content .item {
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
    position: relative;
}
.detail-event-running-lamnha .box-upcoming .box-content .item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background: linear-gradient(0deg, #34beef 0%, rgba(52, 190, 239, 0) 100%);
}
.detail-event-running-lamnha
    .box-upcoming
    .box-content
    .item
    .block-chains-img {
    float: left;

    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
}
.detail-event-running-lamnha
    .box-upcoming
    .box-content
    .item
    .chains-info
    .name {
    font-size: 32px;
    background: linear-gradient(180deg, #35bff0 0%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.detail-event-running-lamnha
    .box-upcoming
    .box-content
    .item
    .chains-info
    .type {
    font-size: 14px;
}
.detail-event-running-lamnha
    .box-upcoming
    .box-content
    .item
    .chains-info
    .name::after {
    -webkit-text-stroke-width: 1.5px;
}
.detail-event-running-lamnha
    .box-upcoming
    .box-content
    .item
    .chains-info
    .name::before {
    border: 0;
}

.detail-event-running-lamnha .box-ended {
    padding: 80px 0;
}
.detail-event-running-lamnha .box-ended .box-title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-ended .handbook-item {
    margin-bottom: 42px;
}
.detail-event-running-lamnha .box-ended .viewmore {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #34beef;
}

.gioithieu-lamnha {
    /* padding: 80px 0;
    background: linear-gradient(180deg, #34beef 0%, #fff 100%); */

    font-weight: 500;
    line-height: 28px;
    position: relative;
}
.gioithieu-lamnha::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event.png") no-repeat top
        center;
    width: 772px;
    height: 347px;
}
.gioithieu-lamnha .box-title-wrap {
    padding: 80px 0;
    background: linear-gradient(180deg, #34beef 0%, #fff 100%);
}
.gioithieu-lamnha .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 42px;
}
.gioithieu-lamnha .box-title .title > span {
    display: inline-block;
    font-family: "SVN-Gilroy";
    font-size: 56px;
    font-weight: 900;
    line-height: 150%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 35%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: -2px;
}
.gioithieu-lamnha .box-title .title > span::after {
    content: attr(title);
    font-family: "SVN-Gilroy";
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.gioithieu-lamnha .box-info {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #fff;
    text-align: center;
}
.gioithieu-lamnha .box-info .desc {
    margin-bottom: 32px;
}
.gioithieu-lamnha .box-info .desc-img img {
    border-radius: 16px;
    overflow: hidden;
    margin: 0 auto;
}
.gioithieu-lamnha .box-whyus-gt {
    padding: 80px 0;
    position: relative;
}
.gioithieu-lamnha .box-whyus-gt .box-info {
    color: #222222;
    text-align: left;
}
.gioithieu-lamnha .box-whyus-gt .box-info p {
    margin-bottom: 32px;
}

.gioithieu-lamnha .box-whyus-gt::before {
    content: "";
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event-2.png") no-repeat top
        center;
    width: 515px;
    height: 181px;
}
.gioithieu-lamnha .box-target-gt {
    padding: 80px 0;
    position: relative;
}
.gioithieu-lamnha .box-target-gt::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../images/lam-nha/bg-detail-event-3.png") no-repeat top
        center;
    width: 635px;
    height: 152px;
}
.gioithieu-lamnha .box-target-gt .box-info {
    color: #222222;
    padding-top: 40px;
}
.gioithieu-lamnha .box-target-gt .list-target {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
}
.gioithieu-lamnha .box-target-gt .list-target .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
}
.gioithieu-lamnha .box-target-gt .list-target .avatar:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 5px;
    background: linear-gradient(to right, #34beef, #ffd703);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}
.gioithieu-lamnha .box-target-gt .list-target .avatar {
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    z-index: 10;
}
.gioithieu-lamnha .box-target-gt .list-target .content {
    position: relative;
    padding: 64px 42px 32px 42px;
    margin-top: -32px;
    flex: 1;
}
.gioithieu-lamnha .box-target-gt .list-target .content::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 5px;
    background: linear-gradient(to right, #34beef, #ffd703);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

.gioithieu-lamnha .box-contribute-gt {
    padding: 80px 0;
}
.gioithieu-lamnha .box-contribute-gt .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    max-width: 550px;
    margin: 0 auto 42px;
    text-align: center;
}
.gioithieu-lamnha .box-contribute-gt .box-info {
    color: #222222;
}
.gioithieu-lamnha .box-contribute-gt .list-contribute {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
}
.gioithieu-lamnha .box-contribute-gt .list-contribute .item {
    display: flex;
    flex-direction: column;
    height: auto;
}
.gioithieu-lamnha .box-contribute-gt .list-contribute .count {
    display: inline-block;
    font-family: "SVN-Gilroy";
    font-size: 48px;
    font-weight: 900;
    line-height: 150%;
    position: relative;
    background: linear-gradient(180deg, #35bff0 0%, #35bff0 35%, #ffd602 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    position: relative;
    z-index: 2;
}

.gioithieu-lamnha .box-contribute-gt .list-contribute .name {
    position: relative;
    padding: 32px 16px 20px 16px;
    font-size: 16px;
    line-height: 24px;
    margin-top: -28px;
    border-radius: 8px;
    border: 1px solid #eee;
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.featured-news-lamnha {
    display: block;
    margin-bottom: 32px;
    position: relative;
}
.featured-news-lamnha .thumbs {
    display: inline-block;
    width: 100%;
    padding-bottom: 41%;
    overflow: hidden;
    position: relative;
}
.featured-news-lamnha .thumbs a > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.featured-news-lamnha .thumbs::before {
    content: "";
    position: absolute;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.featured-news-lamnha .info {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
}
.featured-news-lamnha .info .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: #ffffff;
    max-width: 600px;

    margin-bottom: 20px;
}
.featured-news-lamnha .title.show-mb {
    display: none;
}
.featured-news-lamnha .info .title a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #ffffff;
}
.featured-news-lamnha .info .title a:hover {
    color: #34beef;
}
.news-lamnha .featured-news-box .news-list .handbook-item:first-child,
.news-lamnha .featured-news-box .news-list .handbook-item:nth-child(2) {
    flex: 0 0 calc((100% / 2) - (20px / 2));
    max-width: calc((100% / 2) - (20px / 2));
    margin-bottom: 42px;
}
.featured-news-lamnha .info .share-number {
    display: inline-flex;
    flex-wrap: wrap;
}
.featured-news-lamnha .info .share-number > li {
    display: inline-flex;
    align-self: center;
    padding-right: 36px;
}
.featured-news-lamnha .info .share-number > li > img {
    display: inline-block;
    margin-right: 4px;
    width: 24px;
    height: 24px;
}

/* modal  */
.modal.modal_lamnha_index .modal-header {
    padding: 16px;
    position: relative;
}
.modal.modal_lamnha_index .modal-body {
    padding: 1rem 16px;
}
.modal.modal_lamnha_index .modal-header .close {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 24px;
    right: 24px;
    transform: translateY(-50%);
}
.modal.modal_lamnha_index .lamnha-modal-title {
    font-size: 14px;
    font-weight: 400;
    color: #666;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.modal.modal_lamnha_index .lamnha-modal-title .modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 4px;
    color: #222;
}
.modal_lamnha_index .register-lamnha-form {
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 0;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
}
.modal_lamnha_index .register-lamnha-form > * {
    margin-bottom: 32px;
}
.modal_lamnha_index .register-lamnha-form .custom-control {
    padding-left: 34px;
}
.modal_lamnha_index .register-lamnha-form .item-title {
    margin-bottom: 12px;
    font-weight: 500;
    color: #222;
}
.modal_lamnha_index .register-lamnha-form .item-cols {
    display: flex;
    flex-wrap: wrap;
    /* gap: 12px 8px; */
}
.modal_lamnha_index .register-lamnha-form .item-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.modal_lamnha_index .register-lamnha-form .item-row > div:not(:last-child) {
    margin-bottom: 12px;
}
.modal_lamnha_index .modal-dialog {
    max-width: 100%;
    margin: 0 auto;
}
.modal_lamnha_index .modal-content {
    border-radius: 0;
    border: 0;
}
.modal_lamnha_index .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.modal_lamnha_index .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
}
.modal_lamnha_index .register-lamnha-form .item > .item-cols {
    flex-direction: column;
}
.modal_lamnha_index .register-lamnha-form .item-cols > div {
    flex: 0 0 calc(50% - 4px);
    max-width: calc(50% - 4px);
}
.modal_lamnha_index .register-lamnha-form .item > .item-cols > div {
    flex: 0 0 100%;
    max-width: 100%;
}
.modal_lamnha_index .register-lamnha-form .item-cols > div:not(:last-child) {
    margin-bottom: 12px;
}
.modal_lamnha_index
    .register-lamnha-form
    .item
    > .item-cols
    > div:nth-child(odd) {
    margin-right: 8px;
}
.modal_lamnha_index .custom-back-modal {
    padding: 8px 16px;
    display: block;
}
.modal.modal_lamnha_index .modal-header .close {
    display: none;
}
.order-last-mb {
    order: 10;
    margin-top: 12px;
}
.lamnha-radio-showinput {
    display: flex;
    flex-direction: column;
}
.lamnha-radio-showinput .custom-control-indicator {
    top: 2px;
}
.lamnha-radio-showinput input[type="radio"]:checked ~ input[type="text"] {
    display: block;
}
.lamnha-radio-showinput input[type="text"] {
    margin-top: 12px;
    display: none;
}
@media (min-width: 768px) {
    .modal_lamnha_index .custom-back-modal {
        display: none;
    }
    .modal_lamnha_index .modal-dialog {
        margin: 1.75rem auto;
        height: auto;
    }
    .modal.modal_lamnha_index .modal-header {
        position: relative;
    }
    .modal_lamnha_index .modal-dialog {
        max-width: 92vw;
    }
    .modal_lamnha_index .modal-content {
        border-radius: 12px;
    }
    .modal_lamnha_index .register-lamnha-form > * {
        margin-bottom: 24px;
    }
    .modal_lamnha_index .register-lamnha-form .item > .item-cols {
        /* gap: 12px 8px; */
        flex-direction: row;
    }
    .modal_lamnha_index .register-lamnha-form .item > .item-cols > div {
        flex: 0 0 calc(50% - 4px);
        max-width: calc(50% - 4px);
    }
    .modal.modal_lamnha_index .lamnha-modal-title {
        flex: 1;
        font-size: 18px;
        line-height: 26px;
        flex-direction: column;
        align-items: center;
    }
    .modal.modal_lamnha_index .lamnha-modal-title .modal-title {
        font-size: 32px;
        line-height: 40px;
    }
    .modal.modal_lamnha_index .modal-header .close {
        display: block;
    }
    .order-last-mb {
        order: unset;
        margin-top: 0px;
    }
}
@media (min-width: 1440px) {
    .modal_lamnha_index .modal-dialog {
        max-width: 1280px;
    }
}

.breadcrumb-lamnha {
    padding: 24px 0;
    background: #ffffff;
}
.breadcrumb-lamnha-list {
    display: flex;
    align-items: center;
}
.breadcrumb-lamnha-item a {
    display: flex;
    margin-right: 8px;
    align-items: center;
    color: #666;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
.breadcrumb-lamnha-item:last-child a {
    color: #34beef;
}
.breadcrumb-lamnha-item img {
    width: 20px;
    height: 20px;
}
.breadcrumb-lamnha-item span {
    margin-left: 8px;
}
.fade-chain {
    position: relative;
}
.fade-chain::before {
    content: "";
    position: absolute;

    top: 100px;
}
.fade-chain-1::before {
    left: 0;
    width: 198px;
    height: 147px;
    background: url("../../images/lam-nha/fade-chain-1.svg") no-repeat center
        center;
}
.fade-chain-2::before {
    right: 0;
    width: 341px;
    height: 187px;
    background: url("../../images/lam-nha/fade-chain-2.svg") no-repeat center
        center;
}
.fade-chain-3::before {
    left: 0;
    width: 286px;
    height: 239px;
    background: url("../../images/lam-nha/fade-chain-3.svg") no-repeat center
        center;
}
/* responsive */
@media (max-width: 1440px) {
    .sponsor-lamnha .slick-next {
        right: 0;
    }
    .sponsor-lamnha .slick-prev {
        left: 0;
    }
    .detail-event-running-lamnha .box-info .left {
        flex: 0 0 48.59%;
        max-width: 48.59%;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .info {
        flex: 0 0 calc(50% - 70px);
        max-width: calc(50% - 70px);
    }
}
@media (max-width: 992px) {
    .header-lamnha-main {
        flex-wrap: wrap;
    }
    .header-lamnha-main .menu-lamnha-right-main {
        border-top: 1px solid #eee;
        order: 3;
        width: 100%;
        margin-right: 0;
        margin-top: 16px;
        padding: 16px 0;
    }
    .header-lamnha-main .btn-book {
        margin-left: auto;
    }
    .py-80 {
        padding: 40px 0;
    }
    .breadcrumb-lamnha {
        padding: 20px 0;
    }
    .breadcrumb-lamnha-item a {
        font-size: 14px;
        line-height: 20px;
    }
    .breadcrumb-lamnha-item img {
        width: 16px;
        height: 16px;
    }
    .breadcrumb-lamnha-item span {
        margin-left: 8px;
    }

    .chains-info .name {
        font-size: 32px;
        padding-top: 0;
    }
    .chains-info .name:after {
        top: 0;
    }
    .chains-status {
        font-size: 14px;
        line-height: 20px;
        padding: 4px 8px;
    }
    .chains-info .time {
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 0;
    }
    .chains-info .type {
        padding: 2px 12px 0px 12px;
        font-size: 14px;
        line-height: 20px;
    }
    .chains-info .address {
        margin: 0 auto 12px;
    }
    .chains-info .view-more {
        padding: 12px 16px;
        gap: 0;
    }
    .chains-info .view-more > * {
        margin-left: 8px;
    }
    /* count block */
    .block-chains .box-title .chains-number {
        padding: 10px 24px 8px 24px;
        font-size: 14px;
        line-height: 20px;
    }
    /* title block */
    .block-chains .box-title {
        gap: 0;
        margin-bottom: 32px;
    }
    .block-chains .box-title > *:not(:last-child) {
        margin-bottom: 12px;
    }
    .block-chains .box-title .title {
        font-size: 32px;
        line-height: 40px;
    }

    .featured-event-lamnha {
        background: #34beef;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        .title {
        font-size: 20px;
        line-height: 28px;
    }
    .featured-event-lamnha .featured-event-lamnha-main .featured-event-content {
        gap: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        > *:not(:last-child) {
        margin-bottom: 16px;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        .event-status {
        font-size: 14px;
        padding: 4px 8px;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        .info {
        font-size: 14px;
        column-gap: 0;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        .info
        > *:not(:last-child) {
        margin-right: 6px;
    }
    .roadmap-lamnha {
        padding: 64px 0;
    }
    .roadmap-lamnha .box-content .item .time {
        font-size: 18px;
        line-height: 26px;
    }
    .roadmap-lamnha .box-content .item .info span {
        font-size: 16px;
        font-weight: 500;
    }
    .reasonwhy-lamnha {
        padding: 64px 0;
    }
    .reasonwhy-lamnha .box-content {
        gap: 0;
    }
    .reasonwhy-lamnha .box-content .item {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-bottom: 42px;
    }
    .reasonwhy-lamnha .box-content .item:nth-child(odd) {
        margin-right: 20px;
    }
    .reasonwhy-lamnha .box-content .item-wrap .number {
        font-size: 42px;
        line-height: 56px;
    }
    .register-lamnha .register-lamnha-main {
        gap: 0;
        flex-direction: column;
    }
    .register-lamnha .register-lamnha-main .left {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
    .register-lamnha .register-lamnha-main .left .img {
        padding-top: 40px;
    }
    .register-lamnha .register-lamnha-main .right {
        margin-top: 40px;
    }
    .review-lamnha {
        padding: 64px 0;
    }
    .review-lamnha .box-content .review-list {
        display: grid;
    }
    .review-lamnha .title {
        margin-bottom: 40px;
    }
    .review-lamnha .slick-dots {
        padding-top: 40px;
    }
    .gallery-lamnha .box-content .gallery-list {
        gap: 0;
    }
    .gallery-lamnha .box-content .gallery-list .item {
        flex: 0 0 calc(50% - 12px);
        max-width: calc(50% - 12px);
    }
    .gallery-lamnha .box-content .gallery-list .item:nth-child(odd) {
        margin-right: 24px;
        margin-bottom: 24px;
    }
    .faq-lamnha .faq-item .faq-item-heading {
        font-size: 18px;
        font-weight: 500;
    }

    /* handbook */
    .handbook-lamnha {
        padding: 64px 0;
        position: relative;
    }
    .handbook-lamnha::before {
        top: 24px;
        width: 480px;
        height: 120px;
        background-size: contain;
    }
    .handbook-lamnha .box-title .title {
        font-size: 42px;
    }
    .handbook-list {
        gap: 0;
    }
    .handbook-list .handbook-item {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-bottom: 42px;
    }
    .handbook-list .handbook-item:nth-child(odd) {
        margin-right: 20px;
    }
    .handbook-list .handbook-item .info .views {
        display: none;
    }
    /* news */
    .featured-news-lamnha {
        margin-bottom: 26px;
    }
    .featured-news-lamnha .info .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
    }
    .news-lamnha .news-list .handbook-item {
        margin-right: 16px;
    }
    .news-lamnha .news-list .handbook-item:nth-child(3n) {
        margin-right: 0;
    }
    .news-lamnha .featured-news-box .news-list .handbook-item:first-child {
        margin-right: 16px;
    }
    .news-lamnha .featured-news-box .news-list .handbook-item:nth-child(2) {
        margin-right: 0;
    }
    .news-lamnha .featured-news-box .news-list .handbook-item:first-child,
    .news-lamnha .featured-news-box .news-list .handbook-item:nth-child(2) {
        flex: 0 0 calc((100% / 2) - (8px));
        max-width: calc((100% / 2) - (8px));
        margin-bottom: 42px;
    }
    .news-lamnha .news-list .handbook-item {
        flex: 0 0 calc((100% / 3) - (16px * 2 / 3));
        max-width: calc((100% / 3) - (16px * 2 / 3));
        margin-bottom: 24px;
    }

    .goodwill-lamnha {
        padding: 64px 0;
    }
    .goodwill-lamnha::before {
        top: 24px;
        width: 480px;
        height: 120px;
        background-size: contain;
    }
    .goodwill-lamnha .box-title .title {
        font-size: 42px;
    }
    .goodwill-lamnha .goodwill-list {
        gap: 0;
    }
    .goodwill-lamnha .special-goodwill .thumbs {
        border-radius: 12px;
    }
    .goodwill-lamnha .goodwill-list .goodwill-item {
        flex: 0 0 calc((100% / 3) - (16px * 2 / 3));
        max-width: calc((100% / 3) - (16px * 2 / 3));
        margin-bottom: 24px;
    }
    .goodwill-lamnha .goodwill-list .goodwill-item:not(:nth-child(3n)) {
        margin-right: 16px;
    }
    .detail-event-lamnha {
        padding: 64px 0;
    }
    .detail-event-lamnha .box-title .title {
        font-size: 42px;
    }
    .detail-event-lamnha .box-title .type {
        font-size: 16px;
    }

    .detail-event-lamnha::before,
    .detail-event-running-lamnha::before,
    .gioithieu-lamnha::before,
    .gioithieu-lamnha .box-whyus-gt::before {
        top: 24px;
        width: 480px;
        height: 120px;
        background-size: contain;
    }
    .detail-event-running-lamnha .box-title .title {
        font-size: 42px;
        line-height: 40px;
    }
    .detail-event-running-lamnha .box-title-wrap,
    .detail-event-running-lamnha .box-main-actions {
        padding: 64px 0;
    }
    .detail-event-running-lamnha .box-info {
        flex-wrap: wrap;
    }
    .detail-event-running-lamnha .box-info .left {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 32px;
        height: auto;
    }
    .detail-event-running-lamnha .box-info .right {
        margin-left: 0;
    }
    .detail-event-running-lamnha .box-main-actions .box-tab {
        column-gap: 16px;
    }
    .detail-event-running-lamnha .box-main-actions .box-tab .tab-text {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    .detail-event-running-lamnha .box-main-actions .tab-content-wrap .left {
        padding: 0;
        margin-right: 32px;
    }
    .detail-event-running-lamnha .box-main-actions .tab-content-wrap .right {
        flex: 0 0 36.35%;
        max-width: 36.35%;
    }
    .detail-event-running-lamnha .box-schedule-lamnha {
        padding: 64px 0;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .list-schedule-lamnha {
        padding: 0;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .info {
        max-width: 100%;
        flex: 1;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .count {
        order: -1;
        margin-left: 0;
        margin-right: 24px;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .blank {
        display: none;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item:nth-child(even) {
        flex-direction: row;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item:before,
    .detail-event-running-lamnha .box-schedule-lamnha .item:first-child:before {
        left: 16px;
        transform: translateX(0%);
    }
    .detail-event-running-lamnha .box-register {
        padding: 64px 0;
    }
    .detail-event-running-lamnha .box-upcoming {
        padding: 64px 0 0;
    }
    .detail-event-running-lamnha .box-upcoming .box-content {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        background-color: #fff;
        -webkit-overflow-scrolling: touch;
    }
    .detail-event-running-lamnha .box-upcoming .box-content .item {
        width: 300px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .detail-event-running-lamnha .box-ended {
        padding: 64px 0;
    }
    .detail-event-running-lamnha
        .box-ended
        .handbook-list
        .handbook-item:nth-child(n + 3) {
        display: none;
    }
    .detail-event-running-lamnha .box-ended .handbook-list .handbook-item {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
    }
    .detail-event-running-lamnha
        .box-ended
        .handbook-list
        .handbook-item:first-child {
        margin-right: 20px;
    }
    .gioithieu-lamnha .box-title-wrap,
    .gioithieu-lamnha .box-whyus-gt,
    .gioithieu-lamnha .box-target-gt,
    .gioithieu-lamnha .box-contribute-gt {
        padding: 64px 0;
    }
    .gioithieu-lamnha .box-title .title {
        margin-bottom: 32px;
    }
    .gioithieu-lamnha .box-title .title > span {
        font-size: 42px;
        line-height: 125%;
    }
    .gioithieu-lamnha .box-target-gt .list-target {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }
    .gioithieu-lamnha .box-target-gt .list-target .item {
        flex: 0 0 calc(50% - 12px);
        max-width: calc(50% - 12px);
    }
    .gioithieu-lamnha .box-target-gt .list-target .item:first-child {
        margin-right: 24px;
    }
    .gioithieu-lamnha .box-target-gt .list-target .item:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
        order: -1;
        margin-bottom: 40px;
    }
    .fade-chain-1::before,
    .fade-chain-2::before,
    .fade-chain-3::before {
        width: 144px;
        height: 103px;
        top: 80px;
    }
    .fade-chain-1::before {
        background: url("../../images/lam-nha/fade-chain-1-tablet.svg")
            no-repeat center center;
    }
    .fade-chain-2::before {
        background: url("../../images/lam-nha/fade-chain-2-tablet.svg")
            no-repeat center center;
    }
    .fade-chain-3::before {
        background: url("../../images/lam-nha/fade-chain-3-tablet.svg")
            no-repeat center center;
    }
}

@media (max-width: 768px) {
    .header-lamnha-main .btn-book,
    .header-lamnha-main .menu-lamnha-right-main {
        display: none;
    }
    .breadcrumb-lamnha {
        padding: 12px 0;
    }
    .burger-menu {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 24px;
        height: 24px;
    }
    .burger-menu div {
        width: 24px;
        height: 2px;
        background: #222;
        transition: all 0.3s linear 0s;
        position: relative;
        transform-origin: 1px center;
    }
    .burger-menu.active div:first-child {
        transform: rotate(45deg);
    }
    .burger-menu.active div:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
    }
    .burger-menu.active div:nth-child(3) {
        transform: rotate(-45deg);
    }
    .menu-mb {
        position: fixed;
        width: 100%;
        left: 0;
        top: 74px;
        z-index: 10;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        overflow-y: scroll;
        transition: transform 0.5s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        transform: translate3d(200%, 0, 0);
        padding-bottom: 40px;
    }
    .menu-mb .menu-mb-wrap {
        /* overflow-y: scroll; */
        -webkit-overflow-scrolling: touch;
        /* height: 100%; */
        width: 100%;
        float: left;
        position: relative;
        z-index: 12;
        padding: 40px 24px 0;
        /* height: calc(100% - 180px); */
    }
    .menu-mb .menu-mb-bottom {
        width: 100%;
        /* border-top: 1px solid #d7d7d7; */
        padding: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
    .menu-mb .menu-mb-bottom p {
        margin-bottom: 16px;
    }
    .menu-mb.active {
        transform: translate3d(0, 0, 0);
    }
    .menu-mb .menu-lamnha-link {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 32px;
        color: #101010;
    }
    .menu-mb .menu-lamnha-sub .menu-lamnha-link {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #666;
        margin: 16px 0;
    }
    .banner-lamnha {
        padding: 16px 0;
    }
    .banner-lamnha .wrap .banner-left {
        display: none;
    }
    .banner-lamnha-main .banner-right {
        padding: 0;
        width: 100%;
        margin: 0 auto;
        max-width: 300px;
    }
    .banner-lamnha-mb {
        display: block;
    }
    .banner-lamnha .banner-lamnha-mb .banner-lamnha-img {
        border-radius: 0;
    }
    .banner-lamnha-mb .slick-dots {
        position: relative;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 0 40px;
        padding-top: 16px;
    }
    .banner-lamnha-mb .slick-dots li button {
        background: #ddd;
    }
    .banner-lamnha-mb .slick-dots li.slick-active button {
        background-color: #34beef;
    }
    .featured-event-lamnha .featured-event-lamnha-main .left {
        padding: 0 22.5px;
        width: 100%;
    }
    .featured-event-lamnha .featured-event-lamnha-main .right {
        margin-left: 0;
        margin-top: -24px;
    }
    .featured-event-lamnha .featured-event-lamnha-main .featured-event-content {
        padding: 24px;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        .title {
        font-size: 18px;
        line-height: 26px;
    }
    .featured-event-lamnha
        .featured-event-lamnha-main
        .featured-event-content
        > *:not(:last-child) {
        margin-bottom: 8px;
    }
    .block-chains .box-title .chains-number {
        font-size: 14px;
        padding: 4px 16px;
    }
    .block-chains .box-title .title {
        font-size: 24px;
    }
    .block-chains .box-content .item {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .roadmap-lamnha {
        padding: 40px 0;
    }
    .roadmap-lamnha .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
    }
    .roadmap-lamnha .box-content {
        flex-direction: column;
    }
    .roadmap-lamnha .box-content::before {
        display: none;
    }
    .roadmap-lamnha .box-content .item .item-wrap {
        gap: 0;
    }
    .roadmap-lamnha .box-content .item {
        margin-bottom: 8px;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .roadmap-lamnha .box-content .item .item-wrap .time,
    .roadmap-lamnha .box-content .item .item-wrap .number,
    .roadmap-lamnha .box-content .item .item-wrap .info {
        margin-bottom: 8px;
    }
    .roadmap-lamnha .box-content .item .item-wrap .stroke {
        order: 10;
        margin-bottom: 0;
    }
    .roadmap-lamnha .box-content .item:last-child .stroke {
        display: none;
    }
    .roadmap-lamnha .box-content .item .stroke svg {
        width: 2px;
        height: 24px;
    }
    .reasonwhy-lamnha {
        padding: 40px 0;
    }
    .reasonwhy-lamnha .title {
        font-size: 24px;
        line-height: 32px;
    }
    .reasonwhy-lamnha .box-content .item {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .reasonwhy-lamnha .box-content .item:nth-child(odd) {
        margin-right: 0;
    }
    .register-lamnha .register-lamnha-main .right {
        margin-top: 15px;
    }
    .register-lamnha
        .register-lamnha-main
        .register-lamnha-form
        .item:first-child
        .item-cols,
    .detail-event-running-lamnha
        .box-register
        .register-lamnha-form
        .item:first-child
        .item-cols {
        flex-direction: column;
        gap: 0;
    }
    .register-lamnha
        .register-lamnha-main
        .register-lamnha-form
        .item:first-child
        .item-cols
        > div,
    .detail-event-running-lamnha
        .box-register
        .register-lamnha-form
        .item:first-child
        .item-cols
        > div {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 8px;
    }
    .review-lamnha {
        padding: 40px 0;
    }
    .review-lamnha .title {
        font-size: 24px;
        line-height: 32px;
    }
    .review-lamnha .box-content .review-list .item .item-wrap {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
    .review-lamnha .box-content .review-list .item .avatar {
        margin-bottom: 16px;
    }
    .review-lamnha .box-content .review-list .item .name {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
    }
    .review-lamnha .box-content .review-list .item .rating {
        gap: 0;
        justify-content: center;
    }
    .review-lamnha
        .box-content
        .review-list
        .item
        .rating
        > *:not(:last-child) {
        margin-right: 8px;
    }
    .review-lamnha .box-content .review-list .item .rating img {
        width: 20px;
        height: 20px;
    }
    .review-lamnha .box-content .review-list .item .content {
        text-align: center;
    }
    /* handbook */
    .handbook-lamnha {
        padding: 40px 0;
    }
    .handbook-lamnha::before {
        top: 14px;
        width: 380px;
        height: 90px;
        background-size: contain;
    }
    .handbook-lamnha .box-title .title {
        font-size: 32px;
    }
    .handbook-list {
        gap: 0;
    }
    .handbook-list .handbook-item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
    }
    .handbook-list .handbook-item:nth-child(odd) {
        margin-right: 0px;
    }
    /* news */
    .featured-news-lamnha {
        margin-bottom: 12px;
    }
    .featured-news-lamnha .info {
        padding: 16px;
    }
    .featured-news-lamnha .info .title {
        display: none;
    }
    .featured-news-lamnha .title.show-mb {
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 12px;
    }
    .featured-news-lamnha .title.show-mb a {
        color: #222222;
    }
    .news-lamnha .news-list .handbook-item {
        margin-right: 12px;
    }
    .news-lamnha .news-list .handbook-item:nth-child(2n) {
        margin-right: 0 !important;
    }
    .news-lamnha .featured-news-box .news-list .handbook-item:first-child {
        margin-right: 12px;
    }
    .news-lamnha .news-list .handbook-item:nth-child(3n) {
        margin-right: 12px;
    }
    .news-lamnha .featured-news-box .news-list .handbook-item:nth-child(2) {
        margin-right: 0;
    }
    .news-lamnha .featured-news-box .news-list .handbook-item:first-child,
    .news-lamnha .featured-news-box .news-list .handbook-item:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
    }
    .news-lamnha .news-list .handbook-item {
        flex: 0 0 calc((100% / 2) - (12px / 2));
        max-width: calc((100% / 2) - (12px / 2));
        margin-bottom: 16px;
    }
    .goodwill-lamnha {
        padding: 40px 0;
    }
    .goodwill-lamnha .special-goodwill .thumbs {
        border-radius: 4px;
    }
    .goodwill-lamnha::before {
        top: 14px;
        width: 380px;
        height: 90px;
        background-size: contain;
    }
    .goodwill-lamnha .box-title .title {
        font-size: 32px;
    }
    .goodwill-lamnha .goodwill-list .goodwill-item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
    }
    .goodwill-lamnha .goodwill-list .goodwill-item:not(:nth-child(3n)) {
        margin-right: 0;
    }
    .gallery-lamnha,
    .sponsor-lamnha .show-pc {
        display: none;
    }
    .sponsor-lamnha .show-mb {
        display: block;
    }
    .sponsor-lamnha .title,
    .faq-lamnha .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
    }
    .faq-lamnha .faq-item {
        padding: 16px;
    }
    .faq-lamnha .faq-item .faq-item-heading {
        font-size: 16px;
    }
    .faq-lamnha .faq-item .icon {
        flex: 0 0 24px;
        max-width: 24px;
        height: 24px;
    }
    .detail-event-lamnha {
        padding: 40px 0;
    }
    .detail-event-lamnha .box-title .title {
        font-size: 32px;
    }
    .detail-event-lamnha .box-title .type {
        font-size: 16px;
    }
    .detail-event-lamnha .nd-content {
        gap: 0;
    }
    .detail-event-lamnha .nd-content img {
        border-radius: 8px;
    }
    .detail-event-lamnha .nd-content > *:not(:last-child) {
        margin-bottom: 32px;
    }
    .detail-event-lamnha::before,
    .detail-event-running-lamnha::before {
        top: 14px;
        width: 380px;
        height: 90px;
        background-size: contain;
    }
    .detail-event-running-lamnha .box-title {
        margin-bottom: 32px;
    }
    .detail-event-running-lamnha .box-info {
        padding-top: 0;
    }
    .detail-event-running-lamnha .box-info .left img {
        border-radius: 8px;
    }
    .detail-event-running-lamnha .box-title-wrap,
    .detail-event-running-lamnha .box-main-actions {
        padding: 40px 0;
    }
    .detail-event-running-lamnha .box-main-actions .box-tab {
        width: 100%;
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        background-color: #fff;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 32px;
    }
    .detail-event-running-lamnha .box-main-actions .box-tab > li {
    }
    .detail-event-running-lamnha .box-main-actions .box-tab .tab-text {
        padding: 12px 16px;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
    }
    .detail-event-running-lamnha .box-main-actions .tab-content-wrap {
        flex-direction: column;
    }
    .detail-event-running-lamnha .box-main-actions .tab-content-wrap .left {
        margin-right: 0;
    }
    .detail-event-running-lamnha
        .box-main-actions
        .tab-content-wrap
        .left
        .item {
        margin-bottom: 16px;
    }
    .detail-event-running-lamnha .box-schedule-lamnha,
    .detail-event-running-lamnha .box-register,
    .detail-event-running-lamnha .box-upcoming,
    .detail-event-running-lamnha .box-ended {
        padding: 40px 0;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .box-title,
    .detail-event-running-lamnha .box-register .box-title,
    .detail-event-running-lamnha .box-upcoming .box-title,
    .detail-event-running-lamnha .box-ended .box-title,
    .detail-event-running-lamnha .box-main-actions .box-title {
        font-size: 24px;
        margin-bottom: 32px;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .count {
        margin-right: 8px;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .info-wrap {
        padding: 12px;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item {
        font-size: 16px;
    }
    .detail-event-running-lamnha .box-schedule-lamnha .item .info-wrap .times {
        font-size: 14px;
        padding: 10px;
        margin-right: 12px;
    }
    .detail-event-running-lamnha .box-upcoming .box-content .item {
        width: 244px;
    }
    .detail-event-running-lamnha
        .box-upcoming
        .box-content
        .item
        .chains-info
        .name {
        font-size: 24px;
    }
    .detail-event-running-lamnha .box-ended .handbook-list .handbook-item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 24px;
    }
    .detail-event-running-lamnha
        .box-ended
        .handbook-list
        .handbook-item:first-child {
        margin-right: 0;
    }

    .gioithieu-lamnha .box-title-wrap,
    .gioithieu-lamnha .box-whyus-gt,
    .gioithieu-lamnha .box-target-gt,
    .gioithieu-lamnha .box-contribute-gt {
        padding: 40px 0;
    }
    .gioithieu-lamnha .box-title .title > span {
        font-size: 28px;
        line-height: 125%;
    }
    .gioithieu-lamnha .box-target-gt .list-target {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }
    .gioithieu-lamnha .box-target-gt .list-target .avatar {
        width: 140px;
        height: 140px;
    }
    .gioithieu-lamnha .box-target-gt .list-target .item {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .gioithieu-lamnha .box-target-gt .list-target .item:first-child {
        margin-right: 0;
        margin-bottom: 24px;
    }
    .gioithieu-lamnha .box-target-gt .list-target .item:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
        order: -1;
    }
    .gioithieu-lamnha .box-info .desc-img img {
        border-radius: 8px;
    }
    .gioithieu-lamnha .box-info {
        font-size: 16px;
        line-height: 24px;
    }
    .gioithieu-lamnha .box-target-gt .list-target .content {
        padding: 42px 24px 24px 24px;
    }
    .gioithieu-lamnha .box-target-gt::before {
        top: 14px;
        width: 380px;
        height: 90px;
        background-size: contain;
    }
    .gioithieu-lamnha .box-contribute-gt .title {
        font-size: 24px;
    }
    .gioithieu-lamnha .box-contribute-gt .list-contribute {
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
        row-gap: 12px;
    }
    .gioithieu-lamnha .box-contribute-gt .list-contribute .count {
        font-size: 32px;
    }
    .gioithieu-lamnha .box-contribute-gt .list-contribute .name {
        margin-top: -22px;
        padding: 20px 16px;
    }
    .fade-chain-1::before,
    .fade-chain-2::before,
    .fade-chain-3::before {
        width: 144px;
        height: 103px;
        top: 80px;
    }
    .fade-chain-1::before {
        left: 0;
        background: url("../../images/lam-nha/fade-chain-mb.svg") no-repeat
            center center;
    }
    .fade-chain-2::before {
        left: 0;
        background: url("../../images/lam-nha/fade-chain-mb.svg") no-repeat
            center center;
    }
    .fade-chain-3::before {
        left: 0;
        background: url("../../images/lam-nha/fade-chain-mb.svg") no-repeat
            center center;
    }

    .detail-event-running-lamnha .box-info .item-info {
        flex-wrap: wrap;
    }
    .detail-event-running-lamnha .box-info .item {
        margin-bottom: 16px;
    }
    .detail-event-running-lamnha .box-info .item-info .value {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 576px) {
}
