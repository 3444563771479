.table-don-tu-van .ant-table-thead>tr>th{
    color: #AAA;
    background-color: #F8F8F8;
    font-weight: 500;
}

.table-don-tu-van .ant-table-tbody>tr>td{
    color: #222;
    font-weight: 500;
    vertical-align: middle;
}