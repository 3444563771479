.bb-top-articles {
    margin-top: 20px;
}

.banner-site {
    width: 100%;
    margin-bottom: 30px;
    height: 350px;
    overflow: hidden;
    border-radius: 20px;
}

.banner-site img {
    width: 100%;
    object-fit: contain;
}

.search-full-with {
    width: 100% !important;
}

.wrap-sitemini {
    display: flex;
    justify-content: space-between;
}

.btn--sitemini {
    background: #B80000;
    color: #FFFFFF;
}

.footerInfo .site-mini {
    color: #444444 !important;
    font-size: 13px !important;
    text-transform: initial !important;
    font-weight: normal !important;
}
.logo .site-mini {
    color: #B80000;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
}
.groupCategory-title .readMoreSite{
    color: #B80000 !important;
}
.page-sitemini.active{
    background: #B80000 !important;
    border: 1px solid #B80000 !important;
}
.info-sitemini{
    font-size: 25px;
    font-weight: 600;
    margin: 25px 0;
    color: #222222;
    width: 100%;
}
@media (max-width: 767px) {
    .banner-site {
        height: auto;
    }
    .header .headerTopMini .headerInfo {
        width: 200px;
    }
    .header .headerTopMini .headerControl {
        width: calc(100% - 200px);
    }

}
/**
css filter
 */
.main-site-mini .filterItem .btn--dropdown:hover {
    background: #F9DDE4;
    color: #B80000;
}

.main-site-mini .filterItem .btn--dropdown::after {
    position: absolute;
    top: calc(42% - 4px);
    right: 12px;
    content: "";
    display: block;
    background: url(../images/icon-arrow-mini-site.svg) no-repeat;
    width: 14px;
    height: 12px;
}
.main-site-mini .wrap-bosch .filterItem .btn--dropdown::after {
    position: absolute;
    top: calc(42% - 4px);
    right: 12px;
    content: "";
    display: block;
    background: url(../images/icon-arrow-mini-site-bosch.svg) no-repeat;
    width: 14px;
    height: 12px;
}
.main-site-mini .filterItem .dropdown-menu .filter-list li:hover {
    background: #F9DDE4;
    color: #B80000;
}
.main-site-mini .filterItem .dropdown-menu .filter-list li.active{
    background: #F9DDE4;
    color: #B80000;
}
.main-site-mini .filterItem .dropdown-menu .filter-list li.active::after {
    position: absolute;
    top: calc(42% - 4px);
    right: 15px;
    content: "";
    display: block;
    background: url(../images/Radio-tick-red.svg) no-repeat;
    width: 18px;
    height: 20px;
}
@media (min-width: 767px) {
    .main-site-mini .filterItem .dropdown-menu .filter-list li:hover {
        background: #F9DDE4;
        color: #B80000;
    }
}
.main-site-mini .btn.btn--blue{
    background: #B80000;
    border: 1px solid #B80000;
    color: #fff;
}
.main-site-mini .tag{
    background: #B80000;
}
.groupCategory-title .readMore_bosch{
    color: #007BC0 !important;

}
.readMoreImg{
    width: 15px;
    margin-top: -3px;
}