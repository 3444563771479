.form-dang-ky-tu-van {
    background-color: #fff;
}

.form-dang-ky-tu-van .form-control {
    height: 44px;
    border-radius: 8px;
    margin-top: 12px;
}

.form-dang-ky-tu-van .header-form {
    color: #fff;
    background-image: url('/images/form-tu-van/Header.png');
    background-size: cover;
    padding: 24px;
}

.form-dang-ky-tu-van .header-form .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.form-dang-ky-tu-van .header-form p:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.form-dang-ky-tu-van .body-form {
    padding: 32px 24px 4px 24px;
}

@media (max-width: 767px) {
    .form-dang-ky-tu-van .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}