.font-size-18 {
    font-size: 18px;
}

.hover-color:hover {
 color: #34beef;
}

.no-hover:hover {
    color: unset !important;
    background-color: unset !important;
}

.hover-border-bottom:hover {
    border-bottom: 2px solid #34beef;
    margin-bottom: -2px;
}

.hover-color.active {
    color: #34beef;
}

.hover-border-bottom.active {
    color: #34beef;
    border-bottom: 2px solid #34beef;
    margin-bottom: -2px;
}

.number-result {
    color: #666;
    font-size: 13px;
    margin-top: 24px;
}

.square {
    width: 100%;
}

.square::after {
    content: "";
    display: block;
    padding-top: 100%;
}

/* ----------- color -------------- */
.color-primary {
    color: #34beef;
}
.color-default {
    color: #444;
}
.color-AAA {
    color: #AAA;
}
.color-222 {
    color: #222;
}
.color-666 {
    color: #666;
}
.color-blue {
    color: #278FF7;
}

.color-666 {
    color: #666;
}

.color-222 {
    color: #222;
}

.color-AAA {
    color: #AAA;
}

/* ----------- ul li -------------- */
ul.inline-list {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
}

ul.inline-list li {
    display: inline-block;
}

/* ----------- font -------------- */
.font-size-12 {
    font-size: 12px;
    line-height: 16px;
}
.font-size-13 {
    font-size: 13px;
    line-height: 18px;
}

.font-size-16 {
    font-size: 16px;
    line-height: 24px;
}
.font-size-20 {
    font-size: 20px;
    line-height: 23.44px;
}
.font-size-24 {
    font-size: 24px;
    line-height: 32px;
}
.font-size-32 {
    font-size: 32px;
    line-height: 40px;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}


@media (max-width: 767px) {
    .m-font-size-13 {
        padding-top: 13px;
    }
}

/* ----------- font weight -------------- */
.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

/* ----------- border error -------------- */
.border-error {
    border: 1px solid #f00;
}

.border-radius-12 {
    border-radius: 12px;
}

.border-none {
    border: none;
}

@media (max-width: 767px) {
    .m-border-radius-0 {
        border-radius: 0px;
    }
}

/* ----------- margin -------------- */
.mt-2px {
    margin-top: 2px;
}
.mt-8px {
    margin-top: 8px;
}
.mt-12px {
    margin-top: 12px;
}
.mt-24px {
    margin-top: 24px;
}
.mt-56px {
    margin-top: 56px;
}
.mt-100px {
    margin-top: 100px;
}
.mb-12px {
    margin-bottom: 12px;
}
.mb-24px {
    margin-bottom: 24px;
}
.mt-26px {
    margin-top: 26px;
}
.mb-56px {
    margin-bottom: 56px;
}
.mb-40px {
    margin-bottom: 40px;
}
.mt-12px {
    margin-top: 12px;
}
.mt-24px {
    margin-top: 24px;
}
.mt-48px {
    margin-top: 48px;
}
.mt-50px {
    margin-top: 50px;
}
.mt-56px {
    margin-top: 56px;
}
.mt-100px {
    margin-top: 100px;
}
.mb-12px {
    margin-bottom: 12px;
}
.mb-24px {
    margin-bottom: 24px;
}
.mt-26px {
    margin-top: 26px;
}
.mb-56px {
    margin-bottom: 56px;
}
.mb-40px {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .m-mt--1px {
        margin-top: -1px;
    }
    .m-mb-2px {
        margin-bottom: 2px;
    }
    .m-mt-24px {
        margin-top: 24px;
    }
    .m-ml--16px {
        margin-left: -16px;
    }
    .m-mr--16px {
        margin-right: -16px;
    }
}

/* ----------- padding -------------- */
.px-24px {
    padding-left: 24px;
    padding-right: 24px;
}
.pt-24px {
    padding-top: 24px;
}
.p-12px {
    padding: 12px;
}

.pt-15px {
    padding-top: 15px;
}

.px-12px {
    padding-left: 12px;
    padding-right: 12px;
}

.py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
}

.p-10px {
    padding: 10px;
}

.p-12px {
    padding: 12px;
}

@media (max-width: 767px) {
    .m-pt-0px {
        padding-top: 0px;
    }

    .m-pt-15px {
        padding-top: 15px;
    }

    .m-px-16px {
        padding-left: 16px;
        padding-right: 16px;
    }
}

/* ----------- bg -------------- */
.bg-white {
    background-color: #fff;
}

.bg-bright {
    background-color: #E7FCFD;
}