.main-form-dang-ky {
    padding-top: 60px;
    padding-bottom: 60px;
    max-height: 500px;
    background-image: url('/images/form-tu-van/home-background.png');
    background-size: cover;
    background-position: bottom;
}

.main-form-dang-ky2 {
    margin-bottom: 150px;
}

@media (max-width: 991px) {
    .main-form-dang-ky {
        padding-top: 40px;
        max-height: 800px;
    }
}

@media (max-width: 768px) {
    .main-form-dang-ky {
        padding-top: 24px;
        padding-bottom: 24px;
        max-height: unset;
    }
}

.main-form-dang-ky .intro {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}

.main-form-dang-ky .title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
}

.main-form-dang-ky ul.text-list {
    list-style: none;
}

.text-list li {
    position: relative;
    padding-left: 25px;
}

.text-list li:before {
    content: "\2714";
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 0;
}